import React from "react";
import { Link } from "gatsby";
import sectionIcon from "../images/section-icon.png";
const WhyJci = () => {
    return (
        <section className="about-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="about-heading">
                            <div className="section-heading">
                                <p className="section__desc">
                                    Contributions to JCI India Foundation can be done through our official website, from the donate form {" "}
                                    <Link to="/donate">
                                        foundation.jciindia.in/donate
                                    </Link>
                                </p>
                                <br />
                                <div className="section-icon">
                                    <img src={sectionIcon} alt="section-icon" />
                                </div>
                                <h2 className="section__title">For members of JCI India,</h2>
                                {/* <p className="section__meta">why JCI</p> */}
                                <p className="section__desc">
                                    <ul className="form__list">
                                        <li>
                                            <i className="donate-content-list fa fa-check"></i> All you have to do is select your Zone, LOM and enter your membership ID.
                                        </li>
                                        <li>
                                            <i className=" donate-content-list fa fa-check"></i> All your details will be fetched from the JCI India members portal from there on you can continue to pay the contribution.
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-12">
                        <div className="about-heading">
                            <div className="section-heading">
                                <div className="section-icon">
                                    <img src={sectionIcon} alt="section-icon" />
                                </div>
                                <h2 className="section__title">For non JCI members,</h2>
                                {/* <p className="section__meta">why JCI</p> */}
                                <p className="section__desc">
                                    <ul className="form__list">
                                        <li>
                                            <i className="donate-content-list fa fa-check"></i> You'll be taken to the form to fill in basic information about yourself and continue to payment
                                        </li>
                                        <li>
                                            <i className=" donate-content-list fa fa-check"></i> Upon successful payment, you'll receive a username and password to update your profile in details within JCI India Members Portal.
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-12">
                        <div className="about-heading">
                            <div className="section-heading">
                                <div className="section-icon">
                                    <img src={sectionIcon} alt="section-icon" />
                                </div>
                                <h2 className="section__title">For Organizations,</h2>
                                <p className="section__desc">
                                    <ul className="form__list">
                                        <li>
                                            <i className="donate-content-list fa fa-check"></i> You'll be taken to the form to fill in basic information about yourself and continue to payment
                                        </li>
                                        <li>
                                            <i className=" donate-content-list fa fa-check"></i> Upon successful payment, you'll receive a username and password to update your profile in details within JCI India Members Portal.
                                        </li>
                                        <li>
                                            <i className=" donate-content-list fa fa-check"></i> All contributions to JCI India Foundation are exempted under 80G & 12A of Income tax act so enter your GST or PAN information to avail the exemption benefit
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <br /><br />
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <div className="header-btn ml-auto">
                            <Link to="/donate" className="theme-btn">
                                donate now
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyJci;
